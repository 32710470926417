<template>
    <div class="container">
        <template v-if="pageLoadState === 'loadedSuccess'">
            <div class="container-element">
                <div class="container-element__body">
                    <component
                        ref="collectionComponent"
                        :is="currentComponent"
                        :pageConfig="pageConfig"
                        :activityPageConfig="activityPageConfig"
                        @change-page="changeCurrentPage"
                    ></component>
                </div>
                <div class="container-element__footer" v-if="!isActivityPage" @click="officialWebsiteJump">
                    <img
                        class="img"
                        src="http://wechatapppro-1252524126.file.myqcloud.com/appzpz3qykw5391/image/94a714b73e869009903176abd6e4c486.png"
                    />
                    小鹅通提供技术支持
                </div>
            </div>
        </template>
        <div class="warp-error" v-else-if="pageLoadState === 'loadedFail'">
            <img src="@/assets/images/xiaoe-empty.png" />
            <p>页面加载异常，请联系客服</p>
        </div>
    </div>
</template>

<script>
import { COMPONENTS_MAP } from './components/components-map';
import { getUUID } from './utils/attractive-link-uuid';
const LINK_TYPE_MAP = {
    ATTRACTIVE_LINK: 1,
    ATTRACTIVE_GROUP: 2,
    TRAFFIC_CHANNEL: 3,
    TRAFFIC_GROUP: 4
};
const SHOW_ACTIVITY_PAGE = 1;

export default {
    data() {
        return {
            isWechat: /MicroMessenger/i.test(window.navigator.userAgent) && !/wxwork/i.test(window.navigator.userAgent),
            isPc: !/(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent),
            pageLoadState: 'loading', // loading, loadedSuccess, loadedFail
            pageConfig: {},
            activityPageConfig: {
                is_show_activity_page: null
            },
            groupCodeUrl: '',
            currentPage: '',
            pageTitle: ''
        };
    },
    computed: {
        leadType() {
            return Number(this.$route.query.lead_type);
        },

        currentComponent() {
            return COMPONENTS_MAP[this.leadType];
        },

        showConcatCustomerTips() {
            return this.pageConfig?.open_contact_customer === 1;
        },

        isActivityPage() {
            return this.leadType === LINK_TYPE_MAP['TRAFFIC_GROUP']
                ? this.currentPage === 'activity'
                : false || this.leadType === LINK_TYPE_MAP['ATTRACTIVE_LINK'];
        }
    },
    methods: {
        handleClickOpen(val) {
            this.track('click_butt', { page_button: val });
        },
        changeCurrentPage(page) {
            this.currentPage = page;
        },

        officialWebsiteJump() {
            location.href = 'https://www.xiaoe-tech.com/';
        },

        fetchGenerateScheme() {
            const { lead_type, lead_link_id, app_id } = this.$route.query;
            const attractiveLinkUUID = getUUID();
            this.$ajax('get_lead_link_scheme', {
                lead_type,
                lead_link_id,
                app_id,
                uuid: attractiveLinkUUID
            })
                .then(
                    ({
                        data: {
                            code,
                            data: { mini_app_link, is_mini_app_blocked }
                        }
                    }) => {
                        // 移动端外部浏览器跳转微信小程序
                        if (code === 0 && !is_mini_app_blocked && !this.isPc && !this.isWechat) {
                            window.location.href = mini_app_link;
                            return;
                        }
                        // 渠道活码跳转
                        if (this.leadType === LINK_TYPE_MAP['TRAFFIC_CHANNEL']) {
                            this.$router.replace({
                                path: '/channel_liveCode/middle_page',
                                query: {
                                    app_id,
                                    code_id: lead_link_id,
                                    corp_id: this.$route.query.corp_id
                                }
                            });
                            return;
                        }
                        try {
                            this.fetchSchemeDetail().then(Promise.resolve).catch(Promise.reject);
                        } catch {
                            Promise.reject();
                        }
                    }
                )
                .catch(() => {
                    Promise.reject();
                });
        },
        fetchSchemeDetail() {
            const { lead_type, lead_link_id, app_id } = this.$route.query;
            return this.$ajax('get_lead_link_detail', {
                lead_type,
                lead_link_id,
                app_id
            })
                .then(
                    ({
                        data: {
                            code,
                            data: { card_detail, page_conf }
                        }
                    }) => {
                        if (code === 0) {
                            if (
                                [LINK_TYPE_MAP['ATTRACTIVE_GROUP'], LINK_TYPE_MAP['TRAFFIC_GROUP']].includes(
                                    this.leadType
                                )
                            ) {
                                // 手机微信内访问永久码，直接跳转添加群聊页
                                if (
                                    card_detail.is_show_activity_page !== SHOW_ACTIVITY_PAGE &&
                                    card_detail.group_code_is_permanent &&
                                    !this.isPc &&
                                    this.isWechat &&
                                    card_detail.group_code_url &&
                                    card_detail.delete_state !== 0
                                ) {
                                    location.href = card_detail.group_code_url;
                                    return;
                                }

                                this.pageConfig = { ...card_detail };

                                // 群组活码开启活动页 状态：0-删除,1-正常
                                if (
                                    this.leadType === LINK_TYPE_MAP['TRAFFIC_GROUP'] &&
                                    card_detail.is_show_activity_page === SHOW_ACTIVITY_PAGE &&
                                    card_detail.delete_state === 1
                                ) {
                                    this.getActivityConfig({ app_id, lead_link_id });
                                }
                            }

                            // page_conf 为空时 后端网关转发置为空数组 前端需做兼容
                            if (!Array.isArray(page_conf)) {
                                const { page_conf_detail } = page_conf;
                                this.pageConfig = page_conf_detail;
                                const weworkQRCode = page_conf_detail.elements
                                    .find((item) => item.id === 'container_qr')
                                    .elements.find((item) => item.id === 'wework_qrcode');
                                weworkQRCode.src = card_detail.qr_code;
                            }
                            return Promise.resolve();
                        } else {
                            return Promise.reject();
                        }
                    }
                )
                .catch(() => {
                    return Promise.reject();
                });
        },

        getActivityConfig({ app_id, lead_link_id: group_card_id }) {
            return this.$ajax('get_activity_page_config', { app_id, group_card_id }).then(
                ({ data: { code, data: page_conf } }) => {
                    if (code === 0) {
                        this.activityPageConfig = page_conf;
                        document.title = page_conf.card_header_title;
                    } else {
                        return Promise.reject();
                    }
                }
            );
        },

        addPageUV() {
            const { lead_type, lead_link_id, app_id } = this.$route.query;
            const key = `${app_id}_${lead_type}_${lead_link_id}`;
            const reportedLeadLinkMap = JSON.parse(localStorage.getItem('reportedLeadLinkMap')) || {};
            if (!reportedLeadLinkMap[key]) {
                this.$ajax('add_lead_link_uv', {
                    lead_type,
                    lead_link_id,
                    app_id
                })
                    .then(({ data: { code, data } }) => {
                        if (code === 0) {
                            console.log('uv上报成功');
                            reportedLeadLinkMap[key] = true;
                            localStorage.setItem('reportedLeadLinkMap', JSON.stringify(reportedLeadLinkMap));
                        } else {
                            console.error(`uv上报失败${data.msg}`);
                        }
                    })
                    .catch((err) => {
                        console.error(`uv上报失败${err}`);
                    });
            }
        },
        track(event, params = {}) {
            this.$tracker.track(event, {
                page_id: 'C#wecom_assistant#traffic#drainagelinkh5',
                page_name: '引流链接H5',
                l_program: '企微助手',
                page_path: 'C端#企微助手#引流获客#引流链接H5',
                ...params
            });
        }
    },
    async created() {
        this.$toast.loading();
        this.track('view_page');
        try {
            await this.fetchGenerateScheme();
            this.pageLoadState = 'loadedSuccess';
            this.lead_type !== LINK_TYPE_MAP['TRAFFIC_GROUP'] && this.addPageUV();
        } catch {
            this.pageLoadState = 'loadedFail';
        }
        this.$toast.clear();
        this.pageTitle = document.title;
    },
    beforeDestroy() {
        document.title = this.pageTitle;
    }
};
</script>

<style lang="scss" scoped>
.container {
    height: 100vh;
    font-size: 0.24rem;
    display: flex;
    flex-direction: column;
    .header {
        display: flex;
        align-items: center;
        border-bottom: 1px #eee solid;
        padding: 0.14rem 0.26rem;
        .img {
            width: 0.62rem;
            height: 0.62rem;
            border-radius: 0.2rem;
            margin-right: 0.1rem;
            filter: brightness(1.1);
        }
        .intro {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 0.62rem;
            line-height: 110%;
            .app-name {
                font-weight: 500;
            }
            .tip {
                color: #999;
                font-size: 0.22rem;
            }
        }
        .btn {
            padding: 0.04rem 0.2rem;
            border-radius: 0.24rem;
            color: #fff;
            font-size: 0.28rem;
            background-color: #2879f0;
        }
    }
    .main {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .img {
            width: 1.28rem;
            height: 1.28rem;
        }
        .title {
            font-size: 0.32rem;
            margin-top: 0.32rem;
            color: #000;
        }
        .sub-title {
            color: rgba(0, 0, 0, 0.3);
            display: flex;
            align-items: center;
            margin-top: 0.16rem;
            img {
                width: 0.24rem;
                height: 0.24rem;
                margin-right: 0.06rem;
            }
        }
    }
    footer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .btn {
            background-color: #1472ff;
            color: #fff;
            font-size: 0.34rem;
            border-radius: 0.24rem;
            height: 0.8rem;
            width: 3.6rem;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .alternative {
            color: #9e9e9e;
            font-size: 0.26rem;
            margin: 0.48rem 0 0.8rem;
            a {
                color: #2879f0;
            }
        }
    }

    .container-element {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        overflow-y: auto;
        .tip {
            font-size: 10px;
            font-weight: 400;
            color: #999999;
            margin-top: 0.48rem;
        }

        &__body {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }

        &__footer {
            display: flex;
            align-items: center;
            justify-content: center;
            color: #cccccc;
            font-size: 10px;
            font-weight: 400;
            text-align: center;
            margin: 18px 0px 32px;
            .img {
                width: 12px;
                height: 12px;
                margin-right: 2px;
            }
        }
    }
    .warp-error {
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img {
            width: 2rem;
        }
        p {
            margin-top: 10px;
        }
    }
}
</style>
