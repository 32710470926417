const KEY_ATTRACTIVE_LINK_UUID = 'ATTRACTIVE_LINK_UUID';

// 生成随机数（默认16位） 0123456789abcdef范围内
const generateRandomId = (num = 16) => {
    let str = '0123456789abcdef';
    let res = '';
    for (var i = 0; i < num; i++) {
        let id = Math.ceil(Math.random() * 10);
        res += str.charAt(id);
    }
    return res.toString(16);
};

const createUUID = () => {
    const randomId = generateRandomId();
    const uuid = randomId + Date.now().toString();
    setUUID(uuid);
    return uuid;
};

const setUUID = (uuid) => {
    return localStorage.setItem(KEY_ATTRACTIVE_LINK_UUID, uuid);
};

export const getUUID = () => {
    return localStorage.getItem(KEY_ATTRACTIVE_LINK_UUID) || createUUID();
};
